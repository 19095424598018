import React from 'react'
import { uploadImageToStorage } from "../../../management"
import StorageView from './storageView/StorageView'
import ActivitiesView from './activityView/ActivitiesView'
import ActivityListView from './activityListView/ActivityListView'
import "./ManagementView.css"

export default function ManagementView({ view }) {

    const handleStorageChange = (file) => {
        const uploadedImageURL = uploadImageToStorage(file);
        return uploadedImageURL;
    }

    const handleActivityChange = () => {
        console.log("handle activity change")
    }

    const handleActivitiesListChange = (e) => {
        console.log("handle activities list change",e)
    }

    return (
        <div className='management--view'>
            <h2>{view}</h2>
            {view === "Storage" && <StorageView handleStorageChange={handleStorageChange} />}
            {view === "Activities" && <ActivitiesView handleActivityChange={handleActivityChange} />}
            {view === "Activity list" && <ActivityListView handleActivitiesListChange={handleActivitiesListChange} />}
        </div>
    )
}
