import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getManagementDataFromDB } from '../management';


const initialState = {
    storage: [],
    activities: [],
    activitiesList: [],
    loaded: false,
}

export const loadData = createAsyncThunk(
    'management/initializeItems',
    async (thunkAPI) => {
        return await getManagementDataFromDB();
    });

export const managementSlice = createSlice({
    name: 'management',
    initialState,
    reducers: {
        addStorageImageToState: (state, { payload }) => {
            state.storage.push(payload);
        },
        addActivityToState: (state, { payload }) => {
            state.activities.unshift({ type: payload.type, iconUrl: payload.url, defaultDisplay: payload.name, id: payload.id });
        },
        editActivityInState: (state, { payload }) => {
            const itemIndex = state.activities.findIndex(item => item.id === payload.id);
            if (payload.textType === "type") state.activities[itemIndex].type = payload.value;
            else if (payload.textType === "defaultDisplay") state.activities[itemIndex].defaultDisplay = payload.value;
            else console.log("textType passed through editActivityInState() is not recognized")
        },
        addActivityToActivitiesList: (state, { payload }) => {
            const { time, position, id } = payload;
            state.activitiesList[time][position].items.push(id);
        },
        deleteActivityFromActivitiesList: (state, { payload }) => {
            const { time, position, id } = payload;
            state.activitiesList[time][position].items = state.activitiesList[time][position].items.filter(itemId => itemId !== id);
        },
        updateActivityPositionInState: (state, { payload }) => {
            state.activitiesList[payload.time][payload.position].items = payload.newList;
        },
    },
    extraReducers: {
        [loadData.pending]: (state) => {
            state.loaded = false;
        },
        [loadData.fulfilled]: (state, { payload }) => {
            console.log("payload:", payload)
            state.loaded = true;
            [state.activities, state.activitiesList, state.storage] = payload;
        },
        [loadData.rejected]: (state) => {
            state.loaded = false;
        }
    }
})

export const { addStorageImageToState, addActivityToState, editActivityInState, addActivityToActivitiesList, deleteActivityFromActivitiesList, updateActivityPositionInState } = managementSlice.actions;

export default managementSlice.reducer;