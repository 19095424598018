import React from 'react'
import "./ActivityDataBox.css"

export default function ActivityDataBox({ boxType, textType, text, id, handleEditActivity }) {

    const boxClass = "activity--data--box activity--" + boxType + "--box";

    const renderDataBox = () => {
        if(boxType === "input") return <input
            className={boxClass}
            type="text"
            data-text={textType}
            value={text}
            id={id}
            onChange={(e) => handleEditActivity(e)}
        />
        else if(boxType === "header") return <div
            className={boxClass}
        >{text}</div>
    }

    return (
        <>
            {renderDataBox()}
        </>
    )
}
