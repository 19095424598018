import { initializeApp } from "firebase/app";
import { collection, getFirestore, getDocs, addDoc, updateDoc, doc, arrayUnion, arrayRemove } from "firebase/firestore";
import { getStorage, ref, listAll, getDownloadURL, uploadBytes } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyBo9bZvot4OR4Bj4th5N0dHkSdNko3_gYo",
    authDomain: "winandluz.firebaseapp.com",
    projectId: "winandluz",
    storageBucket: "winandluz.appspot.com",
    messagingSenderId: "184308018567",
    appId: "1:184308018567:web:5d8408f0d19a3411ee3722",
    measurementId: "G-DDV01ZKJWV"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage();

let activitiesCache = null;
let activitiesListCache = null;
let storageCache = null;

//---------------------------------------
async function loadActivities() {
    const querySnapshot = await getDocs(collection(db, 'activities'));
    const items = [];
    querySnapshot.forEach(item => {
        const itemObject = item.data();
        itemObject.id = item.id;
        items.push(itemObject);
    });
    return items;
}

async function loadActivitiesList() {
    const querySnapshot = await getDocs(collection(db, 'activityList'));
    const lists = { morning: {}, evening: {} };
    querySnapshot.forEach(item => {
        const itemObject = item.data();
        itemObject.id = item.id;
        lists[itemObject.type][itemObject.positions] = itemObject;
    });
    return lists;
}

async function loadImagesStorage() {
    try {
        const listRef = ref(storage, 'images');
        const listSnapshot = await listAll(listRef);
        const imageUrlList = await Promise.all(listSnapshot.items.map(async (itemRef) => await getDownloadURL(itemRef)));
        return imageUrlList;
    } catch (err) {
        console.error(err);
    }
}

export async function getManagementDataFromDB() {
    if (!activitiesCache) {
        activitiesCache = await loadActivities();
    }
    if (!activitiesListCache) {
        activitiesListCache = await loadActivitiesList();
    }
    if (!storageCache) {
        storageCache = await loadImagesStorage();
    }
    return [activitiesCache, activitiesListCache, storageCache];
}

//---------------------------------------
export async function uploadImageToStorage(file) {
    try {
        const imagesStorage = ref(storage, "images/" + file.name);
        const metadata = { contentType: file.type };
        const snapshot = await uploadBytes(imagesStorage, file, metadata)
        const imageUrl = await getDownloadURL(imagesStorage);
        console.log('Uploaded.', snapshot, imageUrl)
        return imageUrl;
    } catch (err) {
        alert("The file '" + file.name + "' didn't upload because there is already a file with that name in storage.")
        console.error(err);
    }
}

//---------------------------------------
export async function uploadActivityToDatabase(imageUrl) {
    const docRef = await addDoc(collection(db, "activities"), {
        defaultDisplay: "Placeholder",
        iconUrl: imageUrl,
        type: "Placeholder"
    });
    console.log("Document written with ID: ", docRef.id);
    return [ "Placeholder", "Placeholder", docRef.id ];
}

export async function updateActivityInDatabase(id, value, textType) {
    const activityRef = doc(db, "activities", id);
    await updateDoc(activityRef, { [textType]: value });
}

//---------------------------------------
export async function addActivityIdToActivityList(listId, newActivityId) {
    const activityListRef = doc(db, "activityList", listId);
    await updateDoc(activityListRef, { items: arrayUnion(newActivityId) });
}

//---------------------------------------
export async function deleteActivityIdFromActivityList(listId, targetActivityId) {
    const activityListRef = doc(db, "activityList", listId);
    await updateDoc(activityListRef, { items: arrayRemove(targetActivityId) });
}

//---------------------------------------
export async function updateActivityPositionInDatabase(listId, newList) {
    const activityListRef = doc(db, "activityList", listId);
    await updateDoc(activityListRef, { items: newList });
}