import React from 'react'
import ActivityDataBox from './ActivityDataBox'
import './ActivityLine.css'

export default function ActivityLine({ type, boxType, iconUrl, defaultDisplay, id, tableRow, handleNewActivityOption, handleEditActivity }) {

	return (

		<div className={tableRow}>
			{tableRow === "tableAdd" ?
			<div className='add--box' onClick={() => handleNewActivityOption()}>+</div> : 
			<>
				{iconUrl ?
					<img className='' src={iconUrl} /> :
					<div className='box--imageTitle'>🖻</div>}
				<ActivityDataBox
					boxType={boxType}
					textType="type"
					text={type}
					id={id}
					handleEditActivity={handleEditActivity}
				/>
				<ActivityDataBox
					boxType={boxType}
					textType="defaultDisplay"
					text={defaultDisplay}
					id={id}
					handleEditActivity={handleEditActivity}
				/>
				<div className='box'>{id}</div>
			</>}
		</div>
	)
}
