import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getAllActivities, loadSavedItem } from '../activities';

function creatEmptyItemData(type) {
    return {
        type,
        text: 'פעילות',
        isTextError: false,
        image: '',
        itemType: '',
        activityId: '',
    };
}

function creatDefaultItemData(item, type) {
    return {
        type,
        text: item.defaultDisplay,
        isTextError: false,
        image: item.iconUrl,
        itemType: item.type,
        activityId: item.id,
    };
}

const initialState = {
    items: {
        morning: Array(8).fill(creatEmptyItemData('morning')),
        evening: Array(8).fill(creatEmptyItemData('evening')),
    },
    options: {
        morning: Array(8).fill([]),
        evening: Array(8).fill([]),
    },
    loaded: false,
};

export const loadActivities = createAsyncThunk(
    'activity/initializeItems',
    async (params) => {
        const { orderId, itemId } = params;
        const activities = await getAllActivities();
        const savedData = await loadSavedItem(orderId, itemId);
        return { activities, savedData };
    });



export const activitySlice = createSlice({
    name: 'activity',
    initialState,
    reducers: {
        updateActivity: (state, { payload }) => {
            const { type, position, item } = payload;
            state.items[type][position] = { ...state.items[type][position], ...item};
        },
        updateActivityText: (state, { payload }) => {
            const { type, position, item } = payload;
            state.items[type][position] = { ...state.items[type][position], ...item};
        },
        updateActivityList: (state, { payload }) => {
            const { type, items } = payload;
            state.items[type] = items;
        },
        switchActivity: (state, { payload }) => {
            const { type, firstPosition, secondPosition } = payload;
            const firstItem = state.items[type][firstPosition];
            state.items[type][firstPosition] = state.items[type][secondPosition];
            state.items[type][secondPosition] = firstItem;
        }
    },
    extraReducers: {
        [loadActivities.pending]: (state) => {
            state.loaded = false;
        },
        [loadActivities.fulfilled]: (state, { payload }) => {
            state.loaded = true;
            state.options = payload.activities;
            if(payload.savedData?.item?.current) {
                state.items['morning'] = payload.savedData.item.current.firstBlock;
                state.items['evening'] = payload.savedData.item.current.secondBlock;
            } else {
                state.items['morning'] = state.items['morning'].map((_, index) => creatDefaultItemData(payload.activities['morning'][index][0], 'morning'));
                state.items['evening'] = state.items['evening'].map((_, index) => creatDefaultItemData(payload.activities['evening'][index][0], 'evening'));
            }
        },
        [loadActivities.rejected]: (state) => {
            state.loaded = false;
        }
    }
});

export const { updateActivityText, updateActivity, switchActivity, updateActivityList } = activitySlice.actions;


export default activitySlice.reducer;