import { combineReducers, configureStore } from '@reduxjs/toolkit'
import childNameReducer from './childNameSlice'
import activityReducer from './activitySlice'
import modalReducer from './modalSlice'
import managementReducer from './managementSlice'
import undoable from 'redux-undo';

export default configureStore({
    reducer: undoable(combineReducers({
        childName: childNameReducer,
        activity : activityReducer,
        modal : modalReducer,
        management : managementReducer,
    }),
    {
        filter: (action) => {
            console.log(action.type);
            const blackList = [
                'activity/initializeItems/pending',
                'activity/initializeItems/rejected',
                'modal/toggleModal',
                'modal/updateItemTarget/pending',
                'childName/initializeItems/pending',
                'childName/initializeItems/rejected' ];
            return !blackList.includes(action.type);
        },
        groupBy: (action) => {
            const grouper = {
                'childName/updateName': () => 'childName/updateName',
                'activity/updateActivityText': () => `activity/updateActivityText_${action.payload.type}_${action.payload.position}`,
                'activity/initializeItems/fulfilled': () => 'activity/initializeItems/fulfilled',
            }
            const defaultGrouper = () => null;
            return (grouper[action.type] || defaultGrouper)();
        }
    })
})