import React from "react";
import { toggleInfoModal } from "../dataStore/modalSlice";
import { useSelector, useDispatch } from "react-redux";
import "./InfoModal.css";


export default function InfoModal() {
    const isOpen = useSelector( state => state.present.modal.isInfoOpen );

    const dispatch = useDispatch();

    function closeModal() {
        dispatch(toggleInfoModal())
    }

    function handleOutsideClicks(event) {
        if(event.target.id === "info-modal") {
            closeModal();
        }
    }


    return (
        <div id="info-modal" className={`modal-overlay ${ isOpen ? '' : 'hidden'}`} onClick={ handleOutsideClicks} >
            <div className="modal-dialog" onClick={() => {}}>
                <div className="modal--header">
                    <h2>ברוכים הבאים לעורך הלוחות שלנו!</h2>
                    <span className="close" onClick={closeModal}>&times;</span>
                </div>
                <div className="section block--modal--grid">
                    <div className="info-modal-body">
                        <p>פה תוכלו:</p>
                        <ol>
                            <li>לבחור פעילות חדשה במקום פעילות קיימת<br/><span className="info">(בלחיצה על הריבוע של הפעילות)</span></li>
                            <li>להחליף בין פעילויות קיימות<br/><span className="info">(באמצעות drag&drop - גרירה ומשיכה)</span></li>
                            <li>להחליף את שמות הפעילויות<br/><span className="info">(בלחיצה על הטקסט)</span></li>
                        </ol>
                        <p>
                        שימו<ion-icon name="heart"></ion-icon>
                         לכפתורי ה-Undo (בטל פעולה) וה-Redo (בצע שוב) שבתחתית הדף </p>
                        <div className="info-modal-button-container">
                            <button className="form-button done-button info-modal-button" onClick={closeModal} >הבנתי, אני רוצה להתחיל!</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}