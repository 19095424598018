import React from "react";
import "./BlockTitle.css";

export default function BlockTitle(props) {

    return (
        <div className="block--title" >
            <div className="form">
                <input
                    type="text"
                    placeholder="פעילות"
                    className={`block--titleText  ${props.isError ? 'error-text' : ''}`}
                    value={props.text}
                    onChange={props.handleChange}
                    onKeyDown={props.handleKeyDown}
                />
            </div>
        </div>
    )
}