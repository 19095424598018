import React from 'react'
import { useSelector } from "react-redux";
import PriorityLine from './PriorityLine'
import "./PriorityOrder.css"

export default function PriorityOrder({ time, position, highlight, handleNewActivityOption, handleDeleteActivity, handleActivityMove }) {

    const list = useSelector(state => state.present.management.activitiesList[time][position].items);

    return (
        <div className='priority--order'>
            {highlight && <div>
                <div>Edit {time}, position {position}:</div>
                <div
                    className='add--priority'
                    onClick={() => handleNewActivityOption()}>
                    +
                </div>
                {list.map((item, i) => (<PriorityLine
                    key={i}
                    order={i}
                    id={item}
                    handleDeleteActivity={handleDeleteActivity}
                    handleActivityMove={handleActivityMove}
                />))}
            </div>}
        </div>
    )
}
