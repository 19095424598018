import React, {useState, useEffect} from "react";
import './TimeOfDayTitle.css';

export default function TimeOfDayTitle({title, image, side}) {

    const [timeOfDayTitle, setTimeOfDayTitle] = useState("");
    const [showImage, setShowImage] = useState(false);
    const [titleImage, setTitleImage] = useState("");
    const [imageSide, SetImageSide] = useState("");

    useEffect(()=> {

        setTimeOfDayTitle(title);
        setTitleImage(image);
        SetImageSide(side);
        setShowImage(true);
        
    }, []);

    return (
        <div className="time-label--wrapper">
            {showImage && imageSide === "left" && <img src={titleImage} />}
            <div className="time-label"> {timeOfDayTitle} </div>
            {showImage && imageSide === "right" && <img src={titleImage} />}
        </div>
    );
};