import React from "react";
  
function Home(){
  return (
    <div>
      <h1>
        Home page
      </h1>
    </div>
  );
};
  
export default Home;