import { jsPDF } from 'jspdf';
import {} from './fonts/VarelaRound-Regular-normal';

const MORNING_IMG_URL = 'https://firebasestorage.googleapis.com/v0/b/winandluz.appspot.com/o/logo%2Fsun3.png?alt=media&token=58339b9d-f0bb-4f97-9073-9a51047636fb';
const EVENING_IMG_URL = 'https://firebasestorage.googleapis.com/v0/b/winandluz.appspot.com/o/logo%2Fmoon5.png?alt=media&token=d45e0563-cd18-4052-afa1-663ca9ce99e3';
const LOGO_URL = 'https://firebasestorage.googleapis.com/v0/b/winandluz.appspot.com/o/logo%2Flogo%20v3.png?alt=media&token=e7768b1d-8d14-4f2c-a7d6-f9af91e367a5';

async function toDataURL(url) {
    const response = await fetch(url);
    const blob = await response.blob();
    return await (new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsDataURL(blob)
    }));
}

async function createPlan(kidsName, firstSectionItems, secondSectionItems, progress) {
    const doc = new jsPDF();
    doc.textEnglish = (text, ...opt) => {
        doc.setR2L(false);
        doc.text(text, ...opt);
    };
    doc.textHebrew = (text, ...opt) => {
        doc.setR2L(true);
        doc.text(text, ...opt);
    };
    doc.setFont('VarelaRound-Regular');

    const getPosition = (baseX, baseY, pos, w, h, marginW, marginH) => {
        const line = pos > 3 ? 1 : 0;
        return { x: baseX + (pos % 4) * (w + marginW), y: baseY + line * (h + marginH) };
    };

    const addImage = async (url, position, size) => {
        let imageData = await toDataURL(url);
        doc.addImage(imageData, "PNG", position.x, position.y + 2, size.w, size.h, undefined, 'FAST');
    };

    const addActivityImage = async (list, pos, offset) => {
        const w = 23, h = 23, marginW = 6, marginH = 6;
        const point = getPosition(offset.x, offset.y, pos, w, h, marginW, marginH);
        await addImage(list[pos].image, point, { w, h });
        doc.textHebrew(list[pos].text, point.x + w * 0.5, point.y + h + 2, { align: 'center' });
        doc.rect(point.x - 1.5, point.y + 1, w + 3, h + 3);
    };

    const addImageGroup = async (list, offset, timeOfDay) => {
        doc.setLineDashPattern([2.48, 1.24]);
        doc.setFontSize(10.5);
        doc.setDrawColor('#8CABC4');
        doc.setTextColor('#8CABC4');
        await Promise.all([0,1,2,3,4,5,6,7].map((i) => addActivityImage(list, i, offset)));
    }

    const addMorningTitle = async (offset) => {
        const iconSize = { w: 9, h: 9 };
        const margin = 5;
        const xHeightToLineHeightRatio = 0.65;

        const text = 'בוקר טוב';
        doc.setFontSize(45);
        doc.setTextColor('#EDBE73');
        doc.textHebrew(text, offset.x + 0.5 * (iconSize.w + margin), offset.y, { align: 'center' });
        const textDimension =  doc.getTextDimensions(text);
        const characterHeight =  textDimension.h * xHeightToLineHeightRatio;
        const imgPosition = { x: offset.x - 0.5 * (iconSize.w + margin) - textDimension.w * 0.5 + 2.3, y: offset.y - 0.5 * (characterHeight + iconSize.h ) - 1.75 };
        return { url: MORNING_IMG_URL, position: imgPosition, size: iconSize };
    }

    const addEveningTitle = async (offset) => {
        const iconSize = { w: 9, h: 9 };
        const margin = 5;
        const xHeightToLineHeightRatio = 0.65;

        const text = 'לילה טוב';
        doc.setFontSize(45);
        doc.setTextColor('#EDBE73');
        doc.textHebrew(text, offset.x - 0.5 * (iconSize.w + margin), offset.y, { align: 'center' });

        const textDimension =  doc.getTextDimensions(text);
        const characterHeight =  textDimension.h * xHeightToLineHeightRatio;
        const imgPosition = { x: offset.x - 0.5 * (iconSize.w + margin) + textDimension.w * 0.5 + 2, y: offset.y - 0.5 * (characterHeight + iconSize.h ) - 2 };
        return { url: EVENING_IMG_URL, position: imgPosition, size: iconSize };
    }

    const addName = (text, offset) => {
        doc.setFontSize(66);
        doc.setTextColor('#8CABC4');
        const dimension = doc.getTextDimensions(text);
        doc.textHebrew(text, offset.x + dimension.h * 0.25, offset.y + dimension.w * 0.5, null, 90);
    }

    const addBackground = (offset) => {
        doc.setFillColor('#FAF4E8');
        doc.rect(offset.x, offset.y, 148, 200, "F");
    };

    const addLogo = async (offset, size) => {
        await addImage(LOGO_URL, offset, size);
    };

    const marginOffset = { x: 25, y: 25 };
    const leftLine = 30;
    const sectionStart = 30;
    const sectionHeight = 26 * 2 + 6;
    const sectionMargin = 26;
    const sectionWidth = 26 * 4 + 6 * 3;

    const logoMargin = 0;
    const logoSize = { w: 23, h: 23 };

    progress('יוצר רקע');
    await addBackground(marginOffset);
    progress('כותב שם');
    await addName(kidsName, { x: marginOffset.x + leftLine * 0.5, y: marginOffset.y + sectionStart + sectionHeight + sectionMargin * 0.5 });
    progress('כותרות');
    const [morningImageInfo, eveningImageInfo] = await Promise.all([
        addMorningTitle({ x: marginOffset.x + leftLine + sectionWidth * 0.5 - 6.5, y: marginOffset.y + sectionStart - 4 }),
        addEveningTitle({ x: marginOffset.x + leftLine + sectionWidth * 0.5 - 6.5, y: marginOffset.y + sectionStart + sectionHeight + sectionMargin - 4 })
    ]);
    progress('תמונות');
    await Promise.all([
        addImage(morningImageInfo.url, morningImageInfo.position, morningImageInfo.size),
        addImage(eveningImageInfo.url, eveningImageInfo.position, eveningImageInfo.size),
        addImageGroup(firstSectionItems, { x: marginOffset.x + leftLine, y: marginOffset.y + sectionStart }, 'morning'),
        addImageGroup(secondSectionItems, { x: marginOffset.x + leftLine, y: marginOffset.y + sectionStart + sectionHeight + sectionMargin }, 'evening'),
        addLogo({ x: marginOffset.x + 0.5 * (+17.25 + leftLine + sectionWidth - logoSize.w), y: marginOffset.y + sectionStart + 2 * sectionHeight + sectionMargin + logoMargin }, logoSize)
    ]);
    progress('מסיים את הלוח');
    return doc.output('blob');
}

export function isActionNameFit(text) {
    const doc = new jsPDF();
    doc.setFont('VarelaRound-Regular');
    doc.setFontSize(10.5);
    const textDimension =  doc.getTextDimensions(text);
    return textDimension.w <= 26;
}

export async function createPdf(info, progress) {
    return await createPlan(
        info.childName || "חסר שם",
        info.firstBlock,
        info.secondBlock,
        progress
    );
}