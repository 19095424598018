import React from "react";
import StorageViewImage from "../storageView/StorageViewImage";
import "./ActivitiesListModal.css";

export default function ActivitiesListModal({ options, closeModal, handleAddActivity }) {

    const chooseImage = (id) => {
        closeModal();
        handleAddActivity(id);
    }

    return (
        <div className={`modal-overlay`}>
            <div className="modal-dialog" onClick={() => {}}>
                <div className="modal--header">
                    <h2>Choose activity to add</h2>
                    <span className="close" onClick={() => closeModal()}>&times;</span>
                </div>
                <div className="margin--TopBottom block--modal--grid">
                        {options.map( (activity, i) => <StorageViewImage
                            key={i}
                            id={activity.id}
                            url={activity.iconUrl}
                            overText={activity.defaultDisplay}
                            action={() => chooseImage(activity.id)}
                        /> )}
                </div>
            </div>
        </div>
    )
}