import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { loadSavedItem } from '../activities';

export const loadChildName = createAsyncThunk(
    'childName/initializeItems',
    async (params) => {
        const { orderId, itemId } = params;
        return await loadSavedItem(orderId, itemId);
    });

export const childNameSlice = createSlice({
    name: 'childName',
    initialState: {
        value: ''
    },
    reducers: {
        updateName: (state, action) => {
            state.value = action.payload;
        }
    },
    extraReducers: {
        [loadChildName.pending]: (state) => {
            state.childNameLoaded = false;
        },
        [loadChildName.fulfilled]: (state, { payload }) => {
            state.childNameLoaded = true;
            if (payload.item?.current) {
                state.value = payload.item?.current.childName;
            }
        },
        [loadChildName.rejected]: (state) => {
            state.childNameLoaded = false;
        }
    }
});

export const { updateName } = childNameSlice.actions;

export default childNameSlice.reducer;