import React, { useEffect, useRef, useCallback } from "react";
import BlockWrapper from "../components/blocks/BlockWrapper";
import ChildsNameTitle from "../components/inputs/ChildsNameTitle";
import TimeOfDayTitle from "../components/inputs/TimeOfDayTitle";
import Modal from "../components/Modal";
import InfoModal from "../components/InfoModal";
import { createPdf } from "../pdfCreator";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from 'react-dnd-touch-backend';
import { isMobile } from "react-device-detect";
import { useSelector, useDispatch } from "react-redux";
import { loadActivities } from "../dataStore/activitySlice";
import { loadChildName } from "../dataStore/childNameSlice";
import InfoButton from "../components/InfoButton";
import { ActionCreators } from 'redux-undo';
import { useParams } from 'react-router-dom';
import { saveItem, savePdf } from '../activities';
import '../components/Dropdown.css';
import './project.css';

const DEFAULT_ORDER_PAGE = 'https://www.plan-play.com/test-page';

export default function Project() {

	const { orderId, itemId } = useParams();
	const isOpen = useSelector( state => state.present.modal.isOpen );
	const isInfoOpen = useSelector( state => state.present.modal.isInfoOpen );

	const dispatch = useDispatch();

	const handleKeyPress = useCallback((event) => {
		if(event.ctrlKey && event.key.toUpperCase() === "Z") {
			undo();
		}
		if(event.ctrlKey && event.key.toUpperCase() === "Y") {
			redo();
		}
	// eslint-disable-next-line
	}, []);

	useEffect(() => {
		dispatch(loadActivities({ orderId, itemId }));
		dispatch(loadChildName({ orderId, itemId }));
	}, [dispatch, orderId, itemId]);

	useEffect(() => {
		document.addEventListener('keydown', handleKeyPress)
		return () => {
			document.removeEventListener('keydown', handleKeyPress);
		};
	}, [handleKeyPress]);

	const [status, setStatus] = React.useState({ isLoading: false, action: 'none' });
	const canGenerate = useRef(true);
	const canUndo = useRef(false);
	const canRedo = useRef(false);

	const { firstBlock, secondBlock, childName} = useSelector(state => {
		canUndo.current = state.past.length > 2;
		canRedo.current = state.future.length > 0
		const childName = state.present.childName;
		const firstBlock = state.present.activity.items['morning'] || [];
		const secondBlock = state.present.activity.items['evening'] || [];
		checkItems(firstBlock, secondBlock, childName);
		return { firstBlock, secondBlock, childName };
	});

	function checkItems(firstBlock, secondBlock, childName){
		canGenerate.current = ![...firstBlock, ...secondBlock].some(item => item.isTextError) && !! childName.value;
	}

	function undo(){
		if(canUndo) {
			dispatch(ActionCreators.undo());
		}
	}

	function redo(){
		if(canRedo) {
			dispatch(ActionCreators.redo());
		}
	}

	function getCurentItemData() {
		return { firstBlock, secondBlock, childName: childName.value };
	}

	async function save() {
		function getRedirectLink() {
			const queryParams = new URLSearchParams(window.location.search)
			if (queryParams.has('redirect')) {
				return queryParams.get('redirect');
			}
			return DEFAULT_ORDER_PAGE;
		}

		setStatus({ action: '...', isLoading: true });
		const pdfData = await createPdf(getCurentItemData(), (action) => setStatus(prevStatus => ({ ...prevStatus, action })));
		setStatus({ action: 'יוצר לוח', isLoading: true });
		const pdfUrl = await savePdf(pdfData, orderId, itemId, childName.value);
		const itemData = { ...getCurentItemData(), url: pdfUrl };
		await saveItem(orderId, itemId, itemData);
		const redirectUrl = `${getRedirectLink()}?orderId=${orderId}`;
		setStatus({ action: 'מעביר לעגלת הקניות', isLoading: true });

		if ( window.location !== window.parent.location ) {
			const productInfo = {
				type: 'personalized-task-board',
				orderId: orderId,
				productId: itemId,
				name: childName.value,
				pdf: pdfUrl
			};
			window.parent.postMessage(productInfo, "*");
		} else {
			window.location.assign(redirectUrl);
		}

	}

	const dndBackend = isMobile ? TouchBackend : HTML5Backend;

	return (
		<main>
			{ isOpen && <Modal /> }
			{ isInfoOpen && <InfoModal /> }
			<InfoButton />
			<DndProvider backend={dndBackend}>
				{ status.isLoading ? (<div id="overlay">
					<div className="center">
						<img src="https://firebasestorage.googleapis.com/v0/b/winandluz.appspot.com/o/logo%2Flogo%20v3.png?alt=media&token=e7768b1d-8d14-4f2c-a7d6-f9af91e367a5" alt="logo" />
						<div className="status">{status.action}</div>
					</div>
				</div>) : null }
				<ChildsNameTitle name="childName" placeholder="הכניסו שם" />
				<TimeOfDayTitle
					title="בוקר טוב"
					image="https://firebasestorage.googleapis.com/v0/b/winandluz.appspot.com/o/logo%2Fsun3.png?alt=media&token=58339b9d-f0bb-4f97-9073-9a51047636fb"
					side="left"
				/>
				<BlockWrapper type="morning"/>
				<TimeOfDayTitle
					title="לילה טוב"
					image="https://firebasestorage.googleapis.com/v0/b/winandluz.appspot.com/o/logo%2Fmoon5.png?alt=media&token=d45e0563-cd18-4052-afa1-663ca9ce99e3"
					side="right"
				/>
				<BlockWrapper type="evening"/>
				<div className="footer">
					<div className="form">
						<button disabled={!canGenerate.current} className="form-button done-button" onClick={save.bind(this)} >!סיימתי</button>
					</div>
					<div className="undoRedo">
						<button disabled={!canUndo.current} className="form-button" onClick={() => undo()} alt="Undo"><ion-icon name="arrow-undo"></ion-icon></button>
						<button disabled={!canRedo.current} className="form-button" onClick={() => redo()} alt="Redo"><ion-icon name="arrow-redo"></ion-icon></button>
					</div>
				</div>
			</DndProvider>
		</main>
	);
};