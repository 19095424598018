import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages';
import Management from './pages/management';
import Project from './pages/project';

function App() {
	function autoGenerateId(length) {
		const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
		return Array(length).fill(' ').map(() => characters.charAt(Math.floor(Math.random() * characters.length))).join('')
	}

	function createCustomProjectLink() {
		return `/project/${autoGenerateId(12)}/${autoGenerateId(6)}`;
	}

	return (
		<div className="App">
			<Router>
				<Routes>
					<Route exact path='/' element={<Home />} />
					<Route path='/management' element={<Management />} />
					<Route path='/project' element={<Navigate to={createCustomProjectLink()} replace />} />
					<Route path='/project/:orderId/:itemId' element={<Project />} />
				</Routes>
			</Router>
		</div>
	);
}

export default App;
