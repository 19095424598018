import React from 'react'
import "./StorageViewImage.css";

export default function StorageViewImage({ url, handleUpload, action, overText, id }) {

	// option to create a delete from storage function
	if (action === undefined) action = () => { console.log("This is a placeholder for a selete function") }

	return (
		<div className='storage--view--image'>
			{url ?
				<div className='curserPointer' onClick={() => action()}>
					<img src={url} alt="icon" />
					{overText && <p className='viewImage--overText'>{overText}</p>}
				</div>
				:
				<div className="form--upload">
					<label htmlFor="file-upload" className='add--file'>
						<p className='add--file--icon'>+</p>
						<input type="file" id="file--uplaod" multiple onChange={handleUpload} />
					</label>
				</div>}
		</div>
	)
}
