import React from 'react';
import "./TopicTitleButton.css"

export default function TopicTitleButton({text, handleClick, currentTopic}) {

  const styleClass = (currentTopic === text || currentTopic === "") ? "opacityFull" : "opacityHalf";

  return (
    <div className={styleClass}>
      <button className="management--header--button" onClick={() => handleClick(text)}>
          <div className="management--header--button--text">{text}</div>
      </button>
    </div>
  )
}
