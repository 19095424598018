import React from 'react';
import TopicTitleButton from "./TopicTitleButton";
import "./ManagementHeader.css";

export default function ManagementHeader({handleClick, currentTopic}) {
  return (
    <div className='management--header'>
        <TopicTitleButton text="Storage" handleClick={handleClick} currentTopic={currentTopic} />
        <TopicTitleButton text="Activities" handleClick={handleClick} currentTopic={currentTopic} />
        <TopicTitleButton text="Activity list" handleClick={handleClick} currentTopic={currentTopic} />
    </div>
  )
}
