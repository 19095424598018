import React from 'react'
import ActivityBlock from './ActivityBlock';
import "./ActivitiesOrder.css"

export default function ActivitiesOrder({ handleHighlightChange, time, highlightPosition, activitiesList }) {

    const ShouldBeHighlighted = (itemPosition) => {
        return highlightPosition === itemPosition;
    }

    return (
        <div className='activities--order'>
            {Object.keys(activitiesList[time]).map((position, i) => (<ActivityBlock
                key={i}
                position={position}
                highlighted={ShouldBeHighlighted(position)}
                type={time}
                id={activitiesList[time][position].id}
                itemsPositionList={activitiesList[time][position].items}
                handleHighlightChange={handleHighlightChange}
            />))}
        </div>
    )
}
