import React, { useRef }  from "react";
import { useDrag, useDrop } from "react-dnd";
import { isActionNameFit } from "../../pdfCreator";
import { useSelector, useDispatch } from "react-redux";
import { updateActivityText, switchActivity } from "../../dataStore/activitySlice";
import { toggleModal, updateItemTarget } from "../../dataStore/modalSlice";
import BlockTitle from "./BlockTitle";
import './BlockItem.css';

export default function BlockItem({type, position}) {

    const activity = useSelector(state => state.present.activity.items[type][position] );
    const dispatch = useDispatch();

    const ref = useRef(null)

    const [ { backgroundColor }, drop] = useDrop({
        accept: 'ACTIVITY',
        collect: monitor => ({
            backgroundColor: monitor.isOver() ? "#f8edd6" : "#FAF4E8",
        }),
        drop() {
            return { position, type };
        }
    });

    const [{ opacity }, drag] = useDrag(() => ({
        type: 'ACTIVITY',
        item: { position, type },
        end: (item, monitor) => {
            if (!monitor.didDrop()) {
                return;
            }
            const dropIntoItem = monitor.getDropResult();
            if(type !== dropIntoItem.type) {
                return;
            }
            if(dropIntoItem.position !== item.position) {
                dispatch(switchActivity({ type, firstPosition: dropIntoItem.position, secondPosition: item.position }));
            }
        },
        collect: monitor => ({
            opacity: monitor.isDragging() ? 0.5 : 1,
        }),
    }), [] );

    drag(drop(ref));

    function handleTitleChange(event) {
		const value = event.target.value
        dispatch(updateActivityText({ type, position, item: { text: value, isTextError: !isActionNameFit(value) } }));
	}

    const handleModal = () => {
        dispatch(toggleModal());
        dispatch(updateItemTarget({ type, position }));
    }

    return (
        <div className="block--item" id={activity.activityId} ref={ref} style={{opacity}} >
            <img className="block--img" src={activity.image} alt={activity.itemType} onClick={() => handleModal()}  ref={ref} style={{backgroundColor}}/>
            <BlockTitle
                text={activity.text}
                isError={activity.isTextError}
                handleChange={handleTitleChange}
            />
        </div>
    );
};