import React from "react";
import { toggleModal } from "../dataStore/modalSlice";
import { updateActivity } from "../dataStore/activitySlice";
import { useSelector, useDispatch } from "react-redux";
import "./Modal.css";


export default function Modal() {
    const isOpen = useSelector( state => state.present.modal.isOpen );
    const [type, position] = useSelector( state => state.present.modal.itemTarget );
    const options = useSelector(state => state.present.activity?.options[type][position] );

    const dispatch = useDispatch();

    function closeModal() {
        dispatch(toggleModal())
    }

    function handleOutsideClicks(event) {
        if(event.target.id === "activity-modal") {
            closeModal();
        }
    }

    function createOptionElement(item) {
        return (
            <div className="modal-item" key={`modal-item-${item.id}`} onClick={() => {
                const newState = {
                    activityId: item.id,
                    type: item.type,
                    text: item.defaultDisplay,
                    isTextError: false,
                    image: item?.iconUrl,
                };
                dispatch(updateActivity({ type, position, item: newState }));
                dispatch(toggleModal());
            }}>
                <img src={item?.iconUrl} className="modal-item-image" alt={item?.defaultDisplay}/>
                <span className="modal-item-text">{item.defaultDisplay}</span>
            </div>)
    }

    return (
        <div id="activity-modal" className={`modal-overlay ${ isOpen ? '' : 'hidden'}`} onClick={ handleOutsideClicks}>
            <div className="modal--dialog" onClick={() => {}}>
                <div className="modal--header">
                    <h2>עריכת פעילות</h2>
                    <span className="close" onClick={closeModal}>&times;</span>
                </div>
                <div className="section block--modal--grid">
                    { options.map(item => createOptionElement(item)) }
                </div>
            </div>
        </div>
    )
}