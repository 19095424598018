import React from 'react'
import { useSelector } from "react-redux";
import "./ActivityBlock.css"

export default function ActivityBlock({ position, itemsPositionList, handleHighlightChange, highlighted }) {

	const activityData = useSelector(state => state.present.management.activities.find(item => item.id === itemsPositionList[0]));

	const ActivityBlockClass = highlighted ? 'activity--block highlighted' : 'activity--block';

	return (
		<div>
			<div>{position}</div>
			<div className={ActivityBlockClass}>
				<img
					src={activityData.iconUrl}
					onClick={() => handleHighlightChange(position)}
				/>
			</div>
		</div>
	)
}