import React from 'react'
import { TimeOptionButton } from './TimeOptionButton'
import "./TimeOptions.css"

export default function TimeOptions({ handleTimeChange, currentTime }) {
    return (
        <div className='time--options'>
            <TimeOptionButton
                time="morning"
                imgUrl="https://firebasestorage.googleapis.com/v0/b/winandluz.appspot.com/o/images%2F2.png?alt=media&token=0c8c5927-27d4-4568-87d1-9f95a48ae5a3"
                handleTimeChange={handleTimeChange}
                currentTime={currentTime}
            />
            <TimeOptionButton
                time="evening"
                imgUrl="https://firebasestorage.googleapis.com/v0/b/winandluz.appspot.com/o/images%2F4.png?alt=media&token=82f6a61a-80c5-4496-8814-86ba4f98fe76"
                handleTimeChange={handleTimeChange}
                currentTime={currentTime}
            />
        </div>
    )
}
