import { createSlice } from '@reduxjs/toolkit';

function getInfoModalInitializeState() {
    try {
        return !localStorage.getItem('infoModal')
    } catch (ex) {
        return true;
    }
}

const initialState = {
    isOpen: false,
    isInfoOpen: getInfoModalInitializeState(),
    itemTarget: ['morning', 1]
}

export const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        toggleModal: (state) => {
            state.isOpen = !state.isOpen;
        },
        toggleInfoModal: (state) => {
            state.isInfoOpen = !state.isInfoOpen;
            localStorage.setItem('infoModal', true);
        },
        updateItemTarget: (state, { payload }) =>{
            const { type, position } = payload;
            state.itemTarget = [type, position];
        },
    }
})

export const { toggleModal, toggleInfoModal, updateItemTarget } = modalSlice.actions;

export default modalSlice.reducer;