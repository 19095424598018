import React from 'react'
import { useSelector, useDispatch } from "react-redux";
import { addStorageImageToState } from "../../../../dataStore/managementSlice"
import StorageViewImage from "./StorageViewImage"
import "./StorageView.css";

export default function StorageView({ handleStorageChange }) {

	const storage = useSelector(state => state.present.management.storage);

	const dispatch = useDispatch();


	const handleUpload = (e) => {
		for(let i = 0; i < e.target.files.length; i++){
			retrieveUrlFromUploadedImage(e.target.files[i]);
		}
	}

	async function retrieveUrlFromUploadedImage(fileToUpload) {
		const uploadedImageURL = await handleStorageChange(fileToUpload);
		if(typeof uploadedImageURL === "string"){
			dispatch(addStorageImageToState(uploadedImageURL));
		}
	}

	return (
		<div className='storage--view'>
			<StorageViewImage handleUpload={handleUpload} />
			{storage !== null && storage.map((url, i) => (<StorageViewImage key={i} url={url} />))}
		</div>
	)
}
