import React from "react";
import './BlockWrapper.css';
import BlockItem from "./BlockItem";
import { useSelector } from "react-redux";

export default function BlockWrapper({type}) {

    const activity = useSelector(state => state.present.activity);

    return (
            <div className="block--wrapper">
                <div className="activities--wrapper">
                        {activity.items[type].map((_, i) => (<BlockItem key={i} position={i} type={type} />))}
                </div>
            </div>
    );
};