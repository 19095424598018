import React, { useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { addActivityIdToActivityList, deleteActivityIdFromActivityList, updateActivityPositionInDatabase } from "../../../../management"
import { addActivityToActivitiesList, deleteActivityFromActivitiesList, updateActivityPositionInState } from '../../../../dataStore/managementSlice';
import TimeOptions from './TimeOptions';
import ActivitiesOrder from './ActivitiesOrder';
import PriorityOrder from './PriorityOrder';
import ActivitiesListModal from './ActivitiesListModal';

export default function ActivityListView() {

	const activities = useSelector(state => state.present.management.activities)
	const activitiesList = useSelector(state => state.present.management.activitiesList);

	const dispatch = useDispatch();

	const [time, setTime] = useState("morning");
	const [position, setPosition] = useState(null);
	const [highlight, setHighlight] = useState(false);
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [newActivityOptions, setNewActivityOptions] = useState([]);

	const handleTimeChange = (newTime) => {
		if (newTime !== time) {
			setTime(newTime);
			setHighlight(false);
			setPosition(null);
		}
	}

	const handleHighlightChange = (newPosition) => {
		if (!highlight) setHighlight(true);
		if (newPosition !== position) setPosition(newPosition);
	}

	const handleNewActivityOption = () => {
		setModalIsOpen(true);
		const options = findUnusedActivities();
		if (options.length !== 0) setNewActivityOptions(options);
		else {
			closeModal();
			alert("All activities are in use for this position");
		}
	}

	const findUnusedActivities = () => {
		return activities.filter(activity => canUseId(activity.id))
	}

	const canUseId = (id) => {
		const hasId = activitiesList[time][position].items.some(activityListId => activityListId === id);
		return !hasId;
	}

	const handleAddActivity = async (id) => {
		dispatch( addActivityToActivitiesList({ time, position, id }) );
		addActivityIdToActivityList(activitiesList[time][position].id, id);
	}

	const handleDeleteActivity = async (id) => {
		dispatch( deleteActivityFromActivitiesList({ time, position, id }) );
		deleteActivityIdFromActivityList(activitiesList[time][position].id, id);
	}

	const handleActivityMove = async (id, direction) => {
		const newList = swapActivities(id, direction);
		dispatch(updateActivityPositionInState({ time, position, newList }));
		updateActivityPositionInDatabase(activitiesList[time][position].id, newList);
	}

	const swapActivities = (id, direction) => {
		let itemIndex = activitiesList[time][position].items.findIndex(itemId => itemId === id);
		if (direction === "up") itemIndex--;
		const listLength = activitiesList[time][position].items.length;
		const newList = [];
		for (let i = 0; i < listLength; i++) {
			if (i !== itemIndex) newList.push(activitiesList[time][position].items[i])
			else {
				if (activitiesList[time][position].items[i + 1]) newList.push(activitiesList[time][position].items[i + 1]);
				newList.push(activitiesList[time][position].items[i]);
				i++;
			}
		}
		return newList;
	}

	const closeModal = () => {
		setModalIsOpen(false);
	}

	return (
		<div>
			{modalIsOpen && <ActivitiesListModal
				options={newActivityOptions}
				closeModal={closeModal}
				handleAddActivity={handleAddActivity}
			/>}
			<TimeOptions
				handleTimeChange={handleTimeChange}
				currentTime={time}
			/>
			{activitiesList.length !== 0 && <ActivitiesOrder
				handleHighlightChange={handleHighlightChange}
				time={time}
				highlightPosition={position}
				activitiesList={activitiesList}
			/>}
			{position !== null && <PriorityOrder
				time={time}
				position={position}
				highlight={highlight}
				handleNewActivityOption={handleNewActivityOption}
				handleDeleteActivity={handleDeleteActivity}
				handleActivityMove={handleActivityMove}
			/>}
		</div>
	)
}
