import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateName } from "../../dataStore/childNameSlice";
import "./ChildsNameTitle.css";

export default function ChildsNameTitle({ placeholder, name }) {

    const childName = useSelector(state => state.present.childName);
    const dispatch = useDispatch();

    function setChildName(newName) {
		dispatch(updateName(newName));
	}

    return (
        <div className="childsName--form">
            <input
                type="text"
                placeholder={placeholder}
                className="childsName--input"
                name={name}
                value={childName.value}
                onChange={(e) => setChildName(e.target.value)}
            />
        </div>
    );
};