import React from 'react'
import { useSelector } from "react-redux";
import "./PriorityLine.css"

export default function PriorityLine({ order, id, handleDeleteActivity, handleActivityMove }) {

	const activityData = useSelector(state => state.present.management.activities.find(item => item.id === id));

	return (
		<div className='priority--line'>
			<div className='priority--data order--number'>{order}</div>
			<div className='priority--data moveUp--button' onClick={() => handleActivityMove(id, "up")}>↑</div>
			<div className='priority--data moveDown--button' onClick={() => handleActivityMove(id, "down")}>↓</div>
			<div className='priority--data image--preview'>
				<img src={activityData?.iconUrl} alt="activity" />
			</div>
			<div className='priority--data id--preview'>{id}</div>
			<div className='priority--data delete--button' onClick={() => handleDeleteActivity(id)}>X</div>
		</div>
	)
}
