import React, { useState } from "react";
import StorageViewImage from "../storageView/StorageViewImage";
import "./ActivityModal.css";

export default function ActivityModal({ options, allOptions, closeModal, handleAddActivity }) {

    const [showAll, setShowAll] = useState(false);

    const chooseImage = (url) => {
        closeModal();
        handleAddActivity(url);
    }

    return (
        <div className={`modal-overlay`}>
            <div className="modal-dialog" onClick={() => {}}>
                <div className="modal--header">
                    <h2>Choose image from storage</h2>
                    <span className="close" onClick={() => closeModal()}>&times;</span>
                </div>
                <div className="margin--TopBottom block--modal--grid">
                    { !showAll ?
                        options.map((url,i) => <StorageViewImage key={i} url={url} action={() => chooseImage(url)}/>) :
                        allOptions.map((url,i) => <StorageViewImage key={i} url={url} action={() => chooseImage(url)}/>)
                    }
                </div>
                { !showAll && <button className="show--more--button" onClick={() => setShowAll(true)}>Show all options</button>}
            </div>
        </div>
    )
}