import React, { useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { uploadActivityToDatabase, updateActivityInDatabase } from "../../../../management"
import { addActivityToState, editActivityInState } from '../../../../dataStore/managementSlice';
import ActivityLine from './ActivityLine';
import ActivityModal from './ActivityModal';

export default function ActivitiesView({ handleActivityChange }) {

	const activities = useSelector(state => state.present.management.activities);
	const storage = useSelector(state => state.present.management.storage);

	const dispatch = useDispatch();

	const [newActivityOptions, setNewActivityOptions] = useState([]);
	const [modalIsOpen, setModalIsOpen] = useState(false);

	const handleEditActivity = async (event) => {
		const id = event.target.id;
		const value = event.target.value;
		const textType = event.target.getAttribute("data-text");
        dispatch( editActivityInState({ id, value, textType }) );
		updateActivityInDatabase(id, value, textType);
	}

	const handleAddActivity = async (url) => {
		const [type, name, id] = await uploadActivityToDatabase(url);
		dispatch( addActivityToState({type, url, name, id}) );
	}

	const handleNewActivityOption = () => {
		setModalIsOpen(true);
		const options = findUnusedImages();
		if (options.length !== 0) setNewActivityOptions(options);
		else setNewActivityOptions(storage);
	}

	const findUnusedImages = () => {
		return storage.filter( url => canUseUrl(url) );
	}

	const canUseUrl = (url) => {
		const hasUrl = activities.some( activity => activity.iconUrl === url);
		return !hasUrl;
	}

	const closeModal = () => {
		setModalIsOpen(false);
    }

	return (
		<div className='activities--view'>
			{ modalIsOpen &&  <ActivityModal
				options={newActivityOptions}
				allOptions={storage}
				closeModal={closeModal}
				handleAddActivity={handleAddActivity}
			/>}
			<ActivityLine
				tableRow="tableAdd"
				handleActivityChange={handleActivityChange}
				handleNewActivityOption={handleNewActivityOption}
			/>
			<ActivityLine
				tableRow="tableHead"
				boxType="header"
				handleActivityChange={handleActivityChange}
				type="Type"
				defaultDisplay="Title"
				id="ID"
			/>
			{activities !== null && activities.map((activity, i) => (<ActivityLine
				key={i}
				tableRow="tableData"
				boxType="input"
				type={activity.type}
				iconUrl={activity.iconUrl}
				defaultDisplay={activity.defaultDisplay}
				id={activity.id}
				// alt={activity.alt}
				handleEditActivity={handleEditActivity}
			/>))}
		</div>
	)
}

