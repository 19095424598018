import React from 'react';
import { useDispatch } from "react-redux";
import { toggleInfoModal } from "../dataStore/modalSlice";
import "./InfoButton.css";

export default function InfoButton() {

    const dispatch = useDispatch();

    return (
        <button className="info-button side-right-button" onClick={() => dispatch(toggleInfoModal()) }><ion-icon name="help-circle"></ion-icon></button>
    )
}
