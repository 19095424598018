import { initializeApp } from "firebase/app";
import { collection, getFirestore , getDocs, getDoc, setDoc, doc, updateDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const compareObjects = (a, b) => {
    if (a === b) return true;

    if (typeof a != 'object' || typeof b != 'object' || a == null || b == null) return false;

    let keysA = Object.keys(a), keysB = Object.keys(b);

    if (keysA.length !== keysB.length) return false;

    for (let key of keysA) {
      if (!keysB.includes(key)) return false;

      if (typeof a[key] === 'function' || typeof b[key] === 'function') {
        if (a[key].toString() !== b[key].toString()) return false;
      } else {
        if (!compareObjects(a[key], b[key])) return false;
      }
    }

    return true;
}

const firebaseConfig = {
    apiKey: "AIzaSyBo9bZvot4OR4Bj4th5N0dHkSdNko3_gYo",
    authDomain: "winandluz.firebaseapp.com",
    projectId: "winandluz",
    storageBucket: "winandluz.appspot.com",
    messagingSenderId: "184308018567",
    appId: "1:184308018567:web:5d8408f0d19a3411ee3722",
    measurementId: "G-DDV01ZKJWV"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage();

let activitiesCache = null;
let listCache = null;

async function loadActivities() {
    const querySnapshot = await getDocs(collection(db, 'activities'));
    const items = [];
    querySnapshot.forEach(item => {
        const itemObject = item.data();
        itemObject.id = item.id;
        items.push(itemObject);
    });
    return items;
}

async function loadLists() {
    const querySnapshot = await getDocs(collection(db, 'activityList'));
    const lists = [];
    querySnapshot.forEach(item => {
        const itemObject = item.data();
        itemObject.id = item.id;
        lists.push(itemObject);
    });
    return lists;
}

export async function getActivities(type, position) {
    if (!activitiesCache) {
        activitiesCache = await loadActivities();
    }
    if (!listCache) {
        listCache = await loadLists();
    }
    const relevantListItem = listCache.find(item => item.type === type && item.positions.includes(position));
    const items = relevantListItem.items.map(id => activitiesCache.find(activity => activity.id === id)).filter(Boolean);
    return items;
}

export async function getAllActivitiesForType(type) {
    return await Promise.all([0,1,2,3,4,5,6,7].map(async (_, position) => {
        return await getActivities(type, position)
    }));
}

export async function getAllActivities() {
    const morningItems = await getAllActivitiesForType('morning');
    const eveningItems = await getAllActivitiesForType('evening');

    return {
        morning: morningItems,
        evening: eveningItems,
    };
}

export async function loadSavedItem(orderId, itemId) {
    const orderSnapshot = await getDoc(doc(db, 'orders', orderId));
    const itemSnapshot = await getDoc(doc(db, 'items', itemId));
    return {
        order: orderSnapshot.data(),
        item: itemSnapshot.data()
    }
}

export async function saveItem(orderId, itemId, itemData) {
    try {
        const orderSnapshot = await getDoc(doc(db, 'orders', orderId));
        const itemSnapshot = await getDoc(doc(db, 'items', itemId));
        const itemDoc = doc(collection(db, 'items'), itemId);
        const orderDoc = doc(collection(db, 'orders'), orderId);

        if (orderSnapshot.exists()) {
            if (!orderSnapshot.data().items[itemId]) {
                await updateDoc(orderDoc, { items: Object.assign({ [itemId]: 1 }, orderSnapshot.data().items) });
            }
        } else {
            await setDoc(orderDoc, { items:  { [itemId]: 1}, createdAt: Date.now() });
        }
        if (itemSnapshot.exists()) {
            if( !compareObjects(itemSnapshot.data().current, itemData) ) {
                await updateDoc(itemDoc, { current: itemData, history: [...itemSnapshot.data().history, itemSnapshot.data().current] });
            }
        } else {
            await setDoc(itemDoc, { current: itemData, history: [], createdAt: Date.now() });
        }
    } catch (ex) {
        console.error(ex);
    }
}

export async function savePdf(data, ownerId, itemId, name) {
    const storageRef = ref(storage, `files/${ownerId}/${Date.now()}_${itemId}_${name}.pdf`);
    await uploadBytes(storageRef, data);
    return await getDownloadURL(storageRef);
}