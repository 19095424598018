import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { loadData } from "../dataStore/managementSlice";
import ManagementHeader from "../components/management/header/ManagementHeader";
import ManagementView from "../components/management/view/ManagementView";

function Management() {

	const dispatch = useDispatch();

	const [currentTopic, setCurrentTopic] = useState("");

	useEffect(() => {
		dispatch(loadData());
	}, [dispatch])

	const handleTopicChange = (topic) => {
		if(topic !== currentTopic) setCurrentTopic(topic);
	} 

	return (
		<div>
			<h1>Management dashboard page</h1>
			<ManagementHeader handleClick={handleTopicChange} currentTopic={currentTopic}/>
			<ManagementView view={currentTopic}/>
		</div>
	);
};

export default Management;