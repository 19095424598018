import React from 'react'
import "./TimeOptionButton.css"

export const TimeOptionButton = ({ imgUrl, handleTimeChange, time, currentTime }) => {

	const styleClass = currentTime === time ? "time--option--button outlineHalfVw" : "time--option--button";

	return (
		<div className={styleClass} onClick={() => handleTimeChange(time)}>
			<img src={imgUrl}/>
		</div>
	)
}
